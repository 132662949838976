@import '../function';

.reset-background {
    background-image: url(../../../public/Image/forgotPassword/gaea-forgot.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}

.textHeader {
    color: red;
}


.headerimg {
    position: absolute;
    top: 12vw;
    left: pxToVw(750px, 1920px);
    width: pxToVw(400px, 1920px);
}


.new-text {
    position: relative;
    top: 15vw;
    text-align: center;
    font-size: pxToVw(25px, 1920px);
}

.ResetPassword {
    width: 90% !important;
    padding-top: 20vw;
    margin-top: -2vw !important;
    margin-left: 28vw;
}

#confirm {
    margin-top: 2vw;
}

.validate {
    margin-top: 4vw !important;
    width: 40%;
    margin-left: -10vw;
}

.resetEye {
    position: absolute;
    bottom: 0.4vw;
    right: 1vw;
}

.inputForm {
    border-radius: 10px !important;
}

.resetForm {
    flex-direction: column !important;
}

.resetInput {
    margin-top: 4% !important;
}

@media screen and (max-width: 1000px) {

    .resetForm {
        flex-direction: column !important;
        
            div {
                width: 48%;
            }
        
            div:first-of-type {
                margin-bottom: pxToPourcent(5px, 1920px) !important;
            }
        }
}    